.aks-modal{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow:hidden;
    
    max-height: 100vh;
    z-index: 999;
    font-family: 'Alegreya Sans', Arial, sans-serif;
}

.modal-content {
    
    background: -webkit-gradient(linear, top left, bottom left, from(#00365f), to(#00365f));
	background: -webkit-linear-gradient(#00365f, #00365f);
	background: linear-gradient(#00365f, #00365f);
    width: 85%;
    max-height: 92%;
    backdrop-filter: blur(5px);
    border: 2px white solid;
    border-radius: 5px;
}

.modal-header {
    width: 100%;
    display: flex;
    text-align: center;
    justify-content: center;

    
    padding-top: 15px;
    padding-bottom: 12px;
    border-bottom: 1px rgba(255, 255, 255, 0.3) solid;
}

.model-title {
    text-align: center;
    font-family: 'Alegreya Sans', Arial, sans-serif;
    
}

.modal-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 1px rgba(255, 255, 255, 0.3) solid;
}

.modal-body {
    overflow-y: auto;
    max-height: 90vh;
    padding: 25px 45px;
    text-align: justify;
    text-align-last: left;
    font-family: 'Alegreya Sans', Arial, sans-serif;
    font-size: 1.3rem;

}

 /* width */
 ::-webkit-scrollbar {
    width: 10px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    
    border-radius: 10px;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 5px;
  } 

.modal-exit-button {
    padding: 5px;
    font-size: 1.3rem;
    color: white;
    font-family: 'Alegreya Sans', Arial, sans-serif;
    font-weight: bold;
    background-color: rgba(255, 255, 255, 0);
    border: 2px solid white;
    border-radius: 3px;
}

.modal-exit-button:hover{
    background-color: rgba(255, 255, 255, 0.5);
}
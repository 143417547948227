@import url(https://fonts.googleapis.com/css?family=Alegreya+Sans:300,400,700);



/* ICON STYLES - ICON FROM: http://fontastic.me/
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@font-face {
  font-family: "untitled-font-1";
  src:url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/untitled-font-1.eot");
  src:url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/untitled-font-1.eot#iefix") format("embedded-opentype"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/untitled-font-1.woff") format("woff"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/untitled-font-1.ttf") format("truetype"),
    url("https://s3-us-west-2.amazonaws.com/s.cdpn.io/162656/untitled-font-1.svg#untitled-font-1") format("svg");
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"]:after,
[class*=" icon-"]:after {
  font-family: "untitled-font-1";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-up-open-big { 
  display: inline-block;
  color: white;
}

.icon-up-open-big:after {
  content: "a";
  font-size: 2.5em;
  display: block;
  -webkit-transform: rotateX(180deg);
          transform: rotateX(180deg);
  color: white;
  -webkit-transition: color .3s;
          transition: color .3s;
}

.icon-up-open-big:hover:after {
	color: gray;	
}

.scroll-icon {
	position: absolute;
 	left: 50%;
  bottom: 20px;
  padding: 0 10px;
  	-webkit-transform: translateX(-50%);
  	    -ms-transform: translateX(-50%);
  	        transform: translateX(-50%);

}

@media screen and (max-width: 1000px) {
	.scroll-icon {
	  display: none;
	}
}

@media screen and (max-height: 690px) {
	.scroll-icon {
	  display: none;
	}
}


.scroll-icon p {
	margin-bottom: 0px;
}



/* HELPER CLASSES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.clearfix:before,
.clearfix:after {
  content: "";
  display: table;
}

.clearfix:after {
  clear: both;
}

.l-left {
	float: left;
}

.l-right {
	float: right;
}

.end {
	margin-top: 30px;
	font-size: 3em;
	font-weight: bold;
	opacity: 0;
	-webkit-transform: translateY(300px);
	    -ms-transform: translateY(300px);
	        transform: translateY(300px);
	-webkit-transition: opacity, -webkit-transform 1s;
	        transition: opacity, transform 1s;
	-webkit-transition-delay: 1s;
	        transition-delay: 1s;
}

.blankspace {
	margin-top: 5px;
	margin-bottom: 5px;
}


/* RESET-GENERAL STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

* {
	margin: 0;
	padding: 0;
	font-family: 'Alegreya Sans', Arial, sans-serif;
	/*text-transform: uppercase;*/
}

html {
	font-size: 62.5%;
}

body {
	color: white;
	letter-spacing: .18em;
}

a {
	text-decoration: none;
	color: white;
}

ul, li {
	list-style-type: none;
}



/* NAV STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

.header-top {
	background: rgba(0, 47, 77, .3);
	height: 70px;
	padding: 0 10px;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 12;
	box-sizing: border-box;
}

h1 {
	line-height: 70px;
	height: 70px;
	text-transform: uppercase;
}

h1 a {
	display: block;
	padding: 0 10px;
}

.toggle-menu {
	width: 50px;
	height: 50px;
	display: inline-block;
	position: relative;
	top: 10px;
}

.toggle-menu i {
	position: absolute;
	display: block;
	height: 2px;
	background: white;
	width: 30px;
	left: 10px;
	-webkit-transition: all .3s;
	        transition: all .3s;
}

.toggle-menu i:nth-child(1) {
	top: 16px;
}

.toggle-menu i:nth-child(2) {
	top: 24px;
}

.toggle-menu i:nth-child(3) {
	top: 32px;
}

.open-menu i:nth-child(1) {
	top: 25px;
	-webkit-transform: rotateZ(45deg);
	        transform: rotateZ(45deg);
}

.open-menu i:nth-child(2) {
	background: transparent;
}

.open-menu i:nth-child(3) {
	top: 25px;
	-webkit-transform: rotateZ(-45deg);
	        transform: rotateZ(-45deg);
}

nav {
 	height: 0;
	opacity: 0;
  box-sizing: border-box;
	
	position: fixed;
	top: 70px;
	width: 100%;
  -webkit-transition: all 3s;
          transition: all 3s;
}

.open-menu ~ nav {
	opacity: 1;
 	padding: 80px 0;
	z-index: 15;
	height: calc(90vh - 70px);
}

nav ul {
	padding: 0 10px;
	display: -webkit-box;
	display: -webkit-flex;
	display: -ms-flexbox;
	display: flex;
}

nav li {
	-webkit-box-flex: 1;
	-webkit-flex: 1;
	    -ms-flex: 1;
	        flex: 1;
}

nav li a {
	font-size: 2em;
	display: block;
	padding: 30px;
	text-align: center;
	-webkit-transition: background .3s;
	        transition: background .3s;
}



/* SECTION STYLES - fullPage.js 
–––––––––––––––––––––––––––––––––––––––––––––––––– */

section { 
	text-align: center;
  /*background: url('https://unsplash.it/1910/1221?image=626') no-repeat center / cover;*/
}

p {
	font-size: 1.35rem;
}

h2 {
	text-transform: lowercase;
	font-size: 4em;
	margin-bottom: 20px;
	text-transform: uppercase;
}

h3 {
	font-weight: 300;
	font-size: 2.8em;
	margin-bottom: 10px;
	text-transform: uppercase;
}

h4 {
	text-transform: uppercase;
}



/* SLIDENAV STYLES - fullPage.js 
–––––––––––––––––––––––––––––––––––––––––––––––––– */

#fp-nav ul li a span, 
.fp-slidesNav ul li a span {
	background: white !important;
	width: 8px;
	height: 8px;
	margin: -4px 0 0 -4px;
}

#fp-nav ul li a.active span, 
.fp-slidesNav ul li a.active span, 
#fp-nav ul li:hover a.active span, 
.fp-slidesNav ul li:hover a.active span {
	width: 16px;
	height: 16px;
	margin: -8px 0 0 -8px;
	background: white;
	box-sizing: border-box;
	border: 1px solid green;
}



/* MQ STYLES
–––––––––––––––––––––––––––––––––––––––––––––––––– */

@media screen and (max-width: 767px) {
	nav ul {
		-webkit-box-orient: vertical;
		-webkit-box-direction: normal;
		-webkit-flex-direction: column;
		    -ms-flex-direction: column;
		        flex-direction: column;
	}

	nav li {
		margin-top: 1px;
	}
  
  nav li a {
    font-size: 1.5em;
  }
  
  .scroll-icon {
    display: none;
  }
}

@media screen and (max-width: 400px) {
  html {
    font-size: 50%;
  }
  
  .open-menu ~ nav {
		padding: 20px 0;
	}

	nav li a {
		padding: 3px;
	}
}


.App {
  text-align: center;
}

.section{
  background-size: cover;
  text-align: center;
}



#intro{
  background-image: url(./img/introImage-export2.jpg);
}

#services{
  background: -webkit-gradient(linear, top left, bottom left, from(#00365f), to(#00365f));
  background: -webkit-linear-gradient(#161647, #003b55);
  background: linear-gradient(#00365f, #00365f);
}

#team{
	background: -webkit-gradient(linear, top left, bottom left, from(#00365f), to(#00365f));
	background: -webkit-linear-gradient(#00365f, #00365f);
	background: linear-gradient(#00365f, #00365f);
}


#contact{
	background: -webkit-gradient(linear, top left, bottom left, from(#00365f), to(#00365f));
	background: -webkit-linear-gradient(#00365f, #00365f);
	background: linear-gradient(#00365f, #00365f);
}



#logo{
  width: 100%;
}

#map{
  width: 100%;
  background-color: white;
  height: 50vh;
}

.learnmore{
	display: none;
}


#webcoderskull-area {
	padding: 100px 0;
	text-align: center;
}
#webcoderskull-law-area h2 {
	font-weight: 400;
}

.webcoderskull-item {
	border: 2px solid white;
	margin-bottom: 45px;
	padding: 30px;
	transition: all 0.3s cubic-bezier(.25,.8,.25,1);
	height: 370px;
}

@media screen and (max-width: 1200px) {
	.webcoderskull-item {
	  height: 450px;
	}
}



.webcoderskull-item:hover {
	border: 2px solid #003b55;
}
.webcoderskull-item .fa {
	font-size: 35px;
	margin-bottom: 30px;
}

.webcoderskull-item p {
	font-size: 12px;
}

.webcoderskull-item h3 a {
	color: #fff5f5;
}
.webcoderskull-item h3 a:hover {
	text-decoration: none;
}
.webcoderskull-item:hover h3 a {
	color: #e3e6f8;
}
.webcoderskull-item:hover .learn-more,
.webcoderskull-item:hover h3,
.webcoderskull-item:hover .fa {
	text-decoration: none;
	color: #5b69cb;
}
.webcoderskull-item:hover .learn-more::after {
	content: " \2192";
    opacity: 1;
	transition: all 0.6s ease 0s;
    transition: all 0.6s ease 0s; 
    margin-left: 5px;
    margin-top: 0px;
	line-height: 15px
}
.webcoderskull-item .learn-more::after {
	content: " \2192";
    position: absolute;
    opacity: 0;
    margin-left: -30px;
	transition: all 0.6s ease 0s;
	font-size: 31px;
	line-height: 15px;
    transition: all 0.6s ease 0s; 
}

#contact-row {
	height: 50vh;
}

.gray {
	color: #a5a5a5;
  }
  
  .team{
	margin:40px;
  }
  
  .team-member {
	margin: 15px 0;
	padding: 10px 10px 0px 10px;
	border: 2px solid white;
	
  }
  
  .team-member figure {
	position: relative;
	overflow: hidden;
	padding: 0;
	margin: 0;
	max-height: 500px;
	
  }
  
  .team-member figure img {
	min-width: 100%;
	
  }
  
  .team-member figcaption p {
	font-size: 14px;
  }
  
  .team-member figcaption ul {
	list-style: none;
	margin: 0;
	padding: 0;
  }
  
  .team-member figcaption ul {
	visibility: visible;
	-webkit-transition: all 0.1s ease-in-out;
	-moz-transition: all 0.1s ease-in-out;
	-o-transition: all 0.1s ease-in-out;
	transition: all 0.1s ease-in-out;
  }
  
  .team-member figcaption ul li {
	display: inline-block;
	padding: 10px;
  }
  
  .team-member h4 {
	margin: 10px 0 0;
	padding: 0;
  }
  
  .team-member figcaption {
	padding: 50px;
	color: transparent;
	background-color: transparent;
	position: absolute;
	z-index: 996;
	bottom: 0;
	left: 0;
	width: 100%;
	height: 0;
	overflow: hidden;
	visibility: hidden;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
  }
  
  .team-member figure:hover figcaption {
	visibility: visible;
	color: #fff;
	background: #00344bd2;
	/* Primary color, can be changed via colors.css */
	
	height: 100%;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
  }
  
  .team-member figure:hover figcaption ul li a:hover {
	color: rgba(49, 49, 49, .97);
  }
  
  .team-member figure img {
	-webkit-transform: scale(1) rotate(0) translateY(0);
	-moz-transform: scale(1) rotate(0) translateY(0);
	-o-transform: scale(1) rotate(0) translateY(0);
	-ms-transform: scale(1) rotate(0) translateY(0);
	transform: scale(1) rotate(0) translateY(0);
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;

	width: 100%;
	max-width: 250px;
	width: auto;

  }
  
  .team-member figure:hover img {
	-webkit-transform: scale(1.1) rotate(1deg) translateY(12px);
	-moz-transform: scale(1.1) rotate(1deg) translateY(12px);
	-o-transform: scale(1.1) rotate(1deg) translateY(12px);
	-ms-transform: scale(1.1) rotate(1deg) translateY(12px);
	transform: scale(1.1) rotate(1deg) translateY(12px);
	-webkit-transition: all 0.4s ease-in-out;
	-moz-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
  }

  .teammember-info {
	  text-align: left;
	  padding-top: 20px;
	  font-size: 14px;
  }

  .teammember-info p{
	margin-bottom: 14px;
}

.contact-bottom {
	font-size: 14px;
}

.gdpr-menu {
	position: absolute;
	bottom: 40px;
	right: 40px;
}

.teammember-text {
	text-align: justify;
	text-align-last: left;
}

.justify-centered {
	text-align: justify;
	text-align-last: center;
}

.justify-left {
	text-align: justify;
	text-align-last: left;
}

.contact-details {
	padding-top: 10px;
}

.reveal-text {
	text-align: justify;
}